<script setup>
import { computed, onMounted, reactive, inject } from "vue";

import ChatListHead from "@/features/chat/list/head/ChatListHead.vue";
import ChatListSearchBar from "@/features/chat/list/search/ChatListSearchBar.vue";
import ChatStateSwitch from "@/features/chat/list/switch/ChatStateSwitch.vue";
import ChatListBody from "@/features/chat/list/body/ChatListBody.vue";
import { useChatListStore } from "@/stores/chat-list.store";
import { useUserStore } from "@/stores/user.store";
import { useHttps } from "@/plugins/http";
import { useRouter } from "vue-router";
import { useChatStore } from "@/stores/chat.store";

const socketService = inject("socketService");

const chatListStore = useChatListStore();
const chatStore = useChatStore();
const userStore = useUserStore();
const router = useRouter();
const https = useHttps();

const state = reactive({
  searchKey: "",
});
const currentChat = computed(() => chatStore.getCurrentChat);


const storedUser = computed(() => ({
  id: userStore.getUserId,
}));
const isArchived = computed({
  get: () => chatListStore.getIsArchived,
  set: (v) => chatListStore.setIsArchived(v),
});
const _chats = computed(() =>
  !!!isArchived.value
    ? chatListStore.getUnarchivedChats
    : chatListStore.getArchivedChats
);
const chats = computed(() =>
  state.searchKey !== ""
    ? _chats.value.filter((c) => filterChats(c, storedUser.value))
    : _chats.value
);




const filterChats = (chat, stored) => {
  if (chat.senderID === stored.id) {
    return chat.receiverName
      .toLowerCase()
      .includes(state.searchKey.toLowerCase());
  }
  return chat.senderName.toLowerCase().includes(state.searchKey.toLowerCase());
};

const createChat = async (username) => {
  const response = await https.post("/startChatUser", `UserName=${username}`);
  if (response && response.data && response.data.success) {
    if (response.data.datas) {
      router.push(
        `/chats/${response.data.datas.chatID}?avatar=${response.data.datas.Avatar}&name=${response.data.datas.UserName}&id=${response.data.datas.Id}`
      );
    }
  }
};

onMounted(() => {
  socketService.once(
    "receiveMessage",
    (message, senderName, avatar, senderId, chatId) => {
      const data = {
        chatId,
        message,
        senderName,
        avatar,
        senderId,
      };
      if (chatId === currentChat.value.chatID) {

        chatStore.receiveMessage(data);

      }
      chatListStore.receiveMessage(data);
    }
  );
});

</script>

<template>
  <div class="chat-list-view lg-width-96px">
    <chat-list-head></chat-list-head>
    <chat-list-search-bar v-model:search-key="state.searchKey" @is:create="createChat"></chat-list-search-bar>
    <chat-state-switch :archived="isArchived" @is:click-back="isArchived = false"
      @is:click-archive="isArchived = true"></chat-state-switch>
    <chat-list-body :chats="chats" :searched="!!state.searchKey" :is-archived="isArchived"></chat-list-body>
  </div>
</template>

<style lang="scss">
.chat-list-view {
  width: 380px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding-inline: 15px;
  display: flex;
  flex-direction: column;
}
</style>
