<script setup>
import { reactive, computed, inject, onMounted } from "vue";
import ChatListItem from "../item/ChatListItem.vue";
import DotLoader from "@/components/loader/DotLoader.vue";
import { useUserStore } from "@/stores/user.store";
import { useChatListStore } from "@/stores/chat-list.store";
import VueTypes from "vue-types";
import { useAuthStore } from "@/stores/auth.store";

const displayWidth = inject("displayWidth");
const chatService = inject("chatService");

const userStore = useUserStore();
const authStore = useAuthStore();
const chatListStore = useChatListStore();

const props = defineProps({
  chats: VueTypes.array.required,
  searched: VueTypes.bool.def(false),
  isArchived: VueTypes.bool.def(false),
});
const state = reactive({
  loading: false,
});

const typingChats = computed(() => chatListStore.getTypingChats);
const isNextPage = computed(() =>
  props.searched
    ? false
    : props.chats.length > 0 && props.chats.length % 25 === 0
);
const page = computed(() =>
  !!!props.isArchived
    ? chatListStore.getUnarchivedPage
    : chatListStore.getArchivedPage
);
const storedUser = computed(() => ({
  id: userStore.getUserId,
}));

const getMoreChatList = () => {
  if (props.isArchived) {
    getArchivedChats();
  } else {
    getUnarchivedChats();
  }
};

const getUnarchivedChats = () => {
  chatService.getUnarchivedChats({ page: page.value });
};
const getArchivedChats = () => {
  chatService.getArchivedChats({ page: page.value });
};

onMounted(() => {
  chatService.onMounted({});
});


</script>

<template>
  <div class="chat-list-body-wrapper is-scroll-y scroll-auto scroll-primary scroll-width-0">
    <div class="chat-list-body is-scroll-y scroll-auto scroll-primary scroll-width-0">
      <div class="danger-block-message" v-if="!authStore.getIsChat">
        Chat kullanınımınız yönetici tarafından kısıtlandı! Destek için lütfen
        yöneticinizle iletişime geçin.
      </div>
      <div v-if="state.loading">
        <dot-loader size="1.5em"></dot-loader>
      </div>
      <template v-else-if="chats.length === 0">
        <a-empty image="/img/static/search-engine.png" class="mt-3">
          <template #description>
            <p class="no-margin is-description">
              Görünüşe göre hiç
              {{ isArchived ? "arşivlenmiş" : "arşivlenmemiş" }} sohbetin yok
            </p>
          </template>
        </a-empty>
      </template>
      <div v-else class="chat-list">
        <template v-if="authStore.getIsChat" v-for="chat in chats" :key="chat.chatID">
          <template v-if="displayWidth >= 992 && displayWidth < 1200">
            <a-tooltip :title="storedUser.id == chat.senderID
                ? chat.receiverName
                : chat.senderName
              " placement="right">
              <chat-list-item :current="chat.chatID === $route.params.id" :unread="+chat.senderID === storedUser.id ? false : !chat.receiverSeen
                " :typing="typingChats.includes(chat.chatID)" :date="chat.Datetime" :userId="storedUser.id"
                :senderId="+chat.senderID" :receiverName="chat.receiverName" :senderName="chat.senderName"
                :message="chat.Message" :chat-id="chat.chatID" :avatar="+chat.senderID === storedUser.id
                    ? chat.receiverAvatar
                    : chat.senderAvatar
                  "></chat-list-item>
            </a-tooltip>
          </template>

          <chat-list-item v-else :current="chat.chatID === $route.params.id" :unread="+chat.senderID === storedUser.id ? false : !chat.receiverSeen
            " :typing="typingChats.includes(chat.chatID)" :date="chat.Datetime" :userId="storedUser.id"
            :senderId="+chat.senderID" :receiverName="chat.receiverName" :senderName="chat.senderName"
            :message="chat.Message" :chat-id="chat.chatID" :avatar="+chat.senderID === storedUser.id
                ? chat.receiverAvatar
                : chat.senderAvatar
              "></chat-list-item>
        </template>
        <a-button v-if="isNextPage" class="secondary-button show-more-button" block size="large"
          @click="getMoreChatList">
          <template v-if="displayWidth > 1200 || displayWidth < 992">
            Önceki Sohbetleri Getir
          </template>
          <template v-else>
            <i class="bx bx-plus font-size-180 icon-color"></i>
          </template>
        </a-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.chat-list-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  margin-top: 20px;

  .chat-list-body {
    width: 100%;
    position: absolute;
    padding-bottom: 20px;
  }

  .danger-block-message {
    background-color: #ef5a6f !important;
    color: var(--default-heading-color);
    padding: 0.5rem;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 1rem;
  }
}
</style>
